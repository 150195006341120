<template>
      <div class="row tm-mt-big">
        <div class="col-xl-8 col-lg-10 col-md-12 col-sm-12 mx-auto">
          <div class="bg-white tm-block">
            <div class="row">
              <div class="col-12">
                <h2 class="tm-block-title d-inline-block"> {{ this.id? "Edit": "Add" }}  entry</h2>
              </div>
            </div>
            <div class="row mt-4 tm-edit-product-row">
              <div class="col-xl-12 col-lg-12 col-md-12">
                <form @submit.prevent="submit" action="#" class="tm-edit-product-form">
                  <div class="input-group mb-3">
                    <label for="name" class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-form-label">Title
                    </label>
                    <input v-model="pingr.title" required  placeholder="Short name" type="text"  id="title" name="title" class="form-control validate col-xl-9 col-lg-8 col-md-8 col-sm-7">
                  </div>
                  <div class="input-group mb-3">
                    <label for="name" class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-form-label">Web Address
                    </label>
                    <input v-model="pingr.url" required  placeholder="https://www.my-website-url.com/the/path" type="url"  id="name" name="name" class="form-control validate col-xl-9 col-lg-8 col-md-8 col-sm-7">
                  </div>
                  <div class="input-group mb-3">
                    <label for="category" class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-form-label">Interval</label>
                    <select class="custom-select col-xl-2 col-lg-2 col-md-2 col-sm-2" v-model="pingr.interval">
                      <option v-for="x in schedules" :key="x.id" :value="x.id">{{x.text}}</option>
                    </select>
                  </div>
                  
                  
                  <div class="input-group mb-3">
                    <label for="description" class="col-xl-4 col-lg-4 col-md-4 col-sm-5 mb-2">Description</label>
                    <textarea v-model="pingr.description" class="form-control validate col-xl-9 col-lg-8 col-md-8 col-sm-7" rows="3" placeholder="Details about this address" ></textarea>
                  </div>
                 
                  <div class="input-group mb-3">
                    <label for="stock" class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-form-label">Disabled
                    </label>
                    <input v-model="pingr.disabled"  type="checkbox" aria-label="Checkbox" />
                  </div>

                  <div hidden class="input-group mb-3">
                    <label for="expire_date" class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-form-label">Expire
                                        Date
                    </label>
                    <input placeholder="Expire Date" value="2018-10-28" id="expire_date" name="expire_date" type="text" class="form-control validate col-xl-9 col-lg-8 col-md-8 col-sm-7"
                      data-large-mode="true">
                  </div>

                  <div class="input-group mb-3">
                    <div class="ml-auto col-xl-8 col-lg-8 col-md-8 col-sm-7 pl-0">
                        
                      <button type="submit" class="btn btn-primary"> {{ this.id? "Update": "Create" }} </button> &nbsp;
                      <router-link to="/dashboard"  class="btn btn-primary">Cancel</router-link> &nbsp;
                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name:"EditView",
  computed:{...mapGetters(['isAuthenticated'])},
  data(){return {
    id:undefined,
    pingr:{
      title:"",
      url:"",
      interval:60,
      description:"",
      disabled:false
  },
    schedules:[
//       {
//         id:1,
//         text:"1 minute"
//       },
       {
       id:5,
       text:"5 minutes"
     },
    {
      id:10,
      text:"10 minutes"
    },{
      id:30,
      text:"30 minutes"
    },{
      id:60,
      text:"1 hour"
    },{
      id:120,
      text:"2 hours"
    },{
      id:180,
      text:"3 hours"
    },{
      id:360,
      text:"6 hours"
    },{
      id:720,
      text:"12 Hours"
    },{
      id:1440,
      text:"24 hours"
    }

    ]
  }},
  methods:{
    getHeaders(){
      var token = sessionStorage.getItem("_token_")
        
     var headers = {"authorization":`Bearer ${token}`, 'Accept':'application/json', 'Content-Type': 'application/json'}

     return headers;
    },
    async submit(){
      
      await fetch(this.id?`/api/pingr/${this.id}` :"/api/pingr",{
        method: this.id ?"PUT": "POST",
        body: JSON.stringify( this.pingr),
        headers: this.getHeaders()
      });

      this.$router.push("/dashboard");
    },
    async loadById(id){
      if (!id) return;
      var data = await fetch(`/api/pingr/${id}`,{headers:this.getHeaders()});
      this.pingr =  await data.json();
    }
  },
  mounted(){
    this.id = this.$route.params?.id;
    if (this.id)
      this.loadById(this.id);    
  }
}
</script>