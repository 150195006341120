<template>
    <line-chart id="my-chart-id" :data="cData" :options="chartOptions" />


</template>

<script>
import { Line } from 'vue-chartjs'
import chartjsPluginAnnotation from "chartjs-plugin-annotation"

//import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip
} from 'chart.js'
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    chartjsPluginAnnotation
)



function average(ctx) {
  const values = ctx.chart.data.datasets[0].data;
  return values.reduce((a, b) => parseFloat(a) +parseFloat( b), 0) / values.length;
}

const annotation = {
  type: 'line',
  borderColor: 'grey',
  borderDash: [6, 6],
  borderDashOffset: 0,
  borderWidth: 1,
  label: {
    enabled: true,
    display:true,
    content: (ctx) =>{
        const avg = average(ctx)
        return avg ?
            `Average: ${avg.toFixed(2)} sec  `:
            'No Data!';
        },
    fontSize:10,
    // position: 'end',
    backgroundColor:'grey'
  },
   yMin:(ctx) => average(ctx),
   yMax:(ctx)=> average(ctx),
   value: (ctx) => average(ctx),

};

export default {
    name: 'LineChart',
    components: { 'line-chart': Line },
    
    props: {
        cData: {
            required: true
           
        }
    },
    data() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                scales: {
                    x: {
                        display: false
                    }
                },
                plugins:{
                    annotation: {
                        annotations: {                          
                            annotation,
                            line1: {
          type: 'line',
        //   yMin: 60,
        //   yMax: 60,
          borderColor: 'green',
          borderWidth: 0,
        }
                        }
                    }
                }                
            }
        }
    }
}
</script>
