<template>
  <div class="" id="home">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav class="navbar navbar-expand-xl navbar-light bg-light">
            <router-link  v-if="isAuthenticated" to="/dashboard" class="navbar-brand" >
              <i class="fas fa-3x fa-tachometer-alt tm-site-icon"></i>
              <h1 class="tm-site-title mb-0">PingY</h1>
            </router-link>
            <router-link v-if="!isAuthenticated" to="/" class="navbar-brand" >
              <i class="fas fa-3x fa-tachometer-alt tm-site-icon"></i>
              <h1 class="tm-site-title mb-0">Pingy</h1>
            </router-link>
            
            <button v-show="isAuthenticated" class="navbar-toggler ml-auto mr-0" type="button" data-toggle="collapse"
              data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mx-auto">
                <li  class="nav-item float-left">

                  <router-link  to="/dashboard" v-show="isAuthenticated" class="nav-link" >Dashboard
                    <span class="sr-only">(current)</span>
                </router-link>
                </li>
                <li>

                <router-link  to="/settings" v-show="isAuthenticated" class="nav-link" >Settings
                    <span class="sr-only">(current)</span>
                </router-link>

              </li>
                <li hidden class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    Reports
                  </a>
                  <div hidden class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="#">Daily Report</a>
                    <a class="dropdown-item" href="#">Weekly Report</a>
                    <a class="dropdown-item" href="#">Yearly Report</a>
                  </div>
                </li>
                <li hidden class="nav-item active">
                  <a class="nav-link" href="products.html">Products</a>
                </li>

                <li hidden class="nav-item">
                  <a class="nav-link" href="accounts.html">Accounts</a>
                </li>
                <li hidden class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    Settings
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="#">Profile</a>
                    <a class="dropdown-item" href="#">Billing</a>
                    <a class="dropdown-item" href="#">Customize</a>
                  </div>
                </li>
              </ul>
              <ul class="navbar-nav">
                <li v-show="isAuthenticated" class="nav-item">
                  <a @click="logout" class="nav-link d-flex">
                    <i class="far fa-user mr-2 tm-logout-icon"></i>
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>

      <router-view />

      <footer hidden class="row tm-mt-small fixed-bottom text-center">
        <div class="col-12 font-weight-light">
          <p class="d-inline-block tm-bg-black text-white py-2 px-4">
            Copyright &copy; {{ new Date().getFullYear() }} Pingy Dashboard .
            <!--Created by-->
            <!--  <a rel="nofollow" href="https://www.tooplate.com" class="text-white tm-footer-link">Tooplate</a>-->
          </p>
        </div>
      </footer>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    ...mapActions(['doLogin']),
    logout() {
      //window.location.href = "/.auth/logout";
      sessionStorage.removeItem("_token_");
      this.doLogin();
      this.$router.push("/")
    }
  },
  async created() {
    this.doLogin();

  },
  async mounted(){
    
    document.querySelectorAll('.nav-link').forEach(link => {
            link.addEventListener('click', () => {
                const navbarResponsive = document.getElementById('navbarSupportedContent');
                if (navbarResponsive.classList.contains('show')) {                        
                    const navbarToggler = document.querySelector('.navbar-toggler');
                    navbarToggler.click(); 
                }
            });
        });
  }
}
</script>


<style>

.tm-pointer-icon {
  color: #6e6c6c;
  cursor: pointer;
}

.tm-action-icon:hover,
.tm-action-icon:focus {
  color: #3aabd0;
  cursor: pointer;
}

</style>
