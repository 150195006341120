<template>
    <!-- row -->
    <div class="row tm-mt-big mx-auto">
        <div class="col-12 1mx-auto">
            <!-- <div class=" justify-content-center"> -->
            <img src="/img/landing.png" loading="lazy" class="img-fluid mx-auto d-block" />
            <!-- </div> -->




            <div class="row tm-mt-big">
                <div class="col-12 mx-auto tm-login-col">
                    <div class="bg-white tm-block">

                        <div class="row">
                            <div class="col-12">
                                <div v-show="!doLogin" class="text-center">
                                    <h4 class="tm-login-form">Email with login instructions sent!</h4>
                                    <p>Go to your email provider to login. Click <a :href="emailUrl"> {{ emailUrl }}</a> !</p>
                                </div>
                                <form @submit.prevent="login" v-show="doLogin" class="tm-login-form">
                                    <div class="input-group">
                                        <input v-model="email" placeholder="name@company.com" required
                                            class="form-control validate col-xl-9 col-lg-8 col-md-8 col-sm-12">
                                        <button type="submit" class="btn-1  btn-primary 1mx-auto col-md-4 col-sm-12">{{loginText }}</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="bg-white1 tm-block">
                <div hidden class="row mt-2">
                    <button @click="login" class="btn btn-primary d-inline-block mx-auto bg-white "><i
                            class="fas fa-lock mr-2"></i> GitHub Login / Register</button>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: 'HomeView',
    computed: {
        emailUrl: function () {
            var splitEmail = this.email.split('@');
            return splitEmail.length > 1 ? `https://${splitEmail[1]}` : '';
        }
    },
    data() { return { email: "", isSuccess: false, doLogin: true, loginText: 'Login / Register' } },
    methods: {
        getEmailUrl() {
            if (!this.email) return '';
            var prov = this.email.split('@');
            if (prov.length > 1) return prov[1];
            return '';
        },
        async login() {
            if (!this.email) {
                alert("Email required");
                return;
            }

            this.loginText = "sending...";
            var resp = await fetch(`/api/login/${this.email ?? ''}`);
            if (resp.ok) {
                this.doLogin = false;
                // alert('An email with a link to login has been sent to your email. Follow the link from the email to login!')
                console.log("closing the window...")
            }

        },
        loginSwa() {
            window.location.href = "/.auth/login/github?post_login_redirect_uri=/dashboard";
        }
    }
}
</script>
