<template>
    <!-- row -->
    <div class="row tm-mt-big mx-auto">
        <div class="col-12 mx-auto">
            <!-- <div class=" justify-content-center"> -->
            <!-- <img src="/img/landing.png" loading="lazy" class="img-fluid mx-auto d-block"/> -->
            <!-- </div> -->

            <div class="bg-white1 tm-block">




                <div class="row">
                    <div class="col-12 text-center">
                        <div class="callback">
                            <h2>Login in progress...</h2>
                            <div v-show="error">{{ error }}</div>
                            <p>{{ this.$route.query.email }}</p>
                            <!-- <p>{{ this.$route.query.token }}</p> -->
                            <router-link to="/">return home</router-link>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'CallbackView',
    data(){return{error:""}},
  methods:{
    ...mapActions(['doLogin']),
    async getToken(){
      const query = this.$route.query;
//        const resp = await fetch(`/api/verify?email=${query.email}&token=${encodeURIComponent(query.token)}`)
        const resp = await fetch(`/api/verify?email=${query.email}&token=${query.token}`)
        if (!resp.ok){
            this.error = "Unable to authenticate!"
            console.dir(resp)
            return;
      }
      const token =await resp.text();
      sessionStorage.setItem("_token_", token);
      this.doLogin();
      
console.log("token:");console.log(token)
      setTimeout(() => {
        this.$router.push("/dashboard");
    }, 1000);
      
    }
  },
  mounted(){
    console.dir(this.$route)
    this.getToken();
  }
}
</script>
